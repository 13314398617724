<script>
import TablePage from '@/found/components/table_page';
import FormDetail from './form_detail.vue';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  components: { FormDetail },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = {
      visitUserNames: this.propsObj.row.userCodeList, startVisitDate: this.propsObj.row.beginDate, endVisitDate: this.propsObj.row.endDate, visitBigType: 'VISIT,UNFAMILIAR_VISIT',
    };
    this.getConfigList('sfaCenter_visitManage_visitDetail_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view') return this.btnDetail(row);
    },
    btnDetail(row) {
      this.formName = 'FormDetail';
      this.formConfig.row = { ...row };
      this.modalConfig.title = '查看 详情';
      this.openFull();
    },
  },
};
</script>
