<script>
import TablePage from '@/found/components/table_page';
import VisitDetail from '../visitDetail';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  components: {
    VisitDetail,
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { customerCode: this.propsObj.row.customerCode };
    this.getConfigList('customer_rel_user_details');
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val, 'val');
      if (val.code === 'view_visit_records') {
        this.formName = 'VisitDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '查看拜访记录';
        this.openFull();
      }
    },
  },
};
</script>
