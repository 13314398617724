import TablePage from '../../../../components/table_page';
import TerminalDetail from './terminalDetail';
import UserDetail from './userDetail';

export default {
  name: 'dealer_related_information_report',
  extends: TablePage,
  data() {
    return {};
  },
  components: {
    TerminalDetail,
    UserDetail,
  },
  methods: {
    cellClick({ row, column }) {
      console.log(row, column, 'row, column');
      if (column.property === 'supplyTerminalNum') {
        this.formName = 'TerminalDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '终端信息';
        this.openFull();
      }
      if (column.property === 'marketUserNum') {
        this.formName = 'UserDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '市场支持人员信息';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      if (val.code === 'terminal_information') {
        this.formName = 'TerminalDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '终端信息';
        this.openFull();
      }
      if (val.code === 'market_support_personnel_information') {
        this.formName = 'UserDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '市场支持人员信息';
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('customer_rel_terminal_and user');
  },

};
